<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
        <span class="">课堂详情</span>
        </div>
    </div>
    <div class="bgf v-detail" v-loading="loading">
        <div class="tc padb24 borbot marb30">
            <p class="font20 col279 fontw6 v-d-title">{{ detail.title }}</p>
            <p class="font14 col9 lh20 marb24">{{ detail.createTime }}</p>
        </div>
        <div v-html="detail.content" class="mart40"></div>
    </div>
</template>
<script>
import { Back } from '@element-plus/icons-vue'
export default {
    setup() {
        return {}
    },
    components: {
        Back,
    },
    data() {
        return {
            param: {
                id: this.$route.params.id
            },
            loading: false,
            detail: {}
        }
    },
    methods: {
        async getData() {
            try {
                this.loading = true
                this.detail = await this.$api.base.learningclassroomDetail(this.param)
                console.log('detail',this.detail)
            } catch(e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        this.getData()
    },
}
</script>
<style scoped>
.v-detail {
    padding: 40px 100px;
    margin-top: 20px
}
.v-d-title {
    margin-bottom: 16px;
    line-height: 28px
}
</style>